/* You can add global styles to this file, and also import other style files */
@import "../node_modules/leaflet/dist/leaflet.css";
@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');
$Oswald: 'Oswald', sans-serif;

.alert {
    z-index: 9999 !important;
}
*{
    font-family: $Oswald;
} 
.custom-dialog-class { 
    #hiddenDataList {
        display: none;
      }  
      .mat-dialog-container{
        background-color: transparent;
        border: none !important;
        padding: 0px !important;
        box-shadow: none !important;
      }
  }
table{
    th{
        font-weight: 600 !important;
    }
}
.selected-category-order {
    text-decoration: underline !important;
    color: #3e4244 !important;
    font-weight: 400 !important;
}
.swal2-textarea {
    height: 6.75em !important;
    padding: 0.5em !important;
    font-size: 14px !important;
}
.table>thead>tr>th, table th {
    font-size: 15px !important;
    font-weight: 500 !important;
}
.mat-dialog-content{
    max-height: unset;
    overflow: hidden;
}
// .alert-success alert-with-icon animated fadeInDown