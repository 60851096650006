/*
 * bootstrap-tagsinput v0.8.0
 *
 */
@mixin tag-input-theme($theme) {
    display: block;
    position: relative;
    transition: map-get($theme, transition);
    padding: map-get($theme, 'container-padding');
    min-height: map-get($theme, 'container-height');
    cursor: text;
    border-bottom: map-get($theme, 'container-border-bottom');
    box-shadow: map-get($theme, 'box-shadow');
    border-radius: map-get($theme, 'border-radius');

    &:focus {
        outline: 0;
    }

    &.ng2-tag-input--dropping {
        opacity: 0.7;
    }

    &.ng2-tag-input--focused {
        border-bottom: map-get($theme, 'container-border-bottom-focused');
    }

    &.ng2-tag-input--invalid {
        border-bottom: map-get($theme, 'container-border-bottom-invalid');
    }

    &.ng2-tag-input--loading {
        border: none;
    }

    &.ng2-tag-input--disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    form {
        margin: map-get($theme, 'container-margin');
    }
}
@mixin error-message($theme) {
    font-size: map-get($theme, 'error-message-font-size');
    color: map-get($theme, 'error-message-color');
    margin: map-get($theme, 'error-message-margin');
}
@mixin tag-theme($theme) {
    font-family: map-get($theme, 'font-family');
    font-weight: map-get($theme, 'font-weight');
    font-size: map-get($theme, 'font-size');
    letter-spacing: map-get($theme, 'letter-spacing');
    color: map-get($theme, 'color') !important;
    border-radius: map-get($theme, 'border-radius');
    border: map-get($theme, 'border');
    margin: map-get($theme, 'margin');
    padding: map-get($theme, 'padding');
    height: map-get($theme, 'height');
    line-height: map-get($theme, 'line-height');
    background: map-get($theme, 'background');
    text-transform: map-get($theme, 'text-transform');
    height: 24px;
    white-space: nowrap;
    display: inline-block;
    cursor: pointer;
    position: relative;
    background-color: $brand-primary !important;
    -webkit-transition: all 150ms ease-in;
    -moz-transition: all 150ms ease-in;
    -o-transition: all 150ms ease-in;
    -ms-transition: all 150ms ease-in;
    transition: all 150ms ease-in;

    &:not(.readonly):not(.tag--editing):focus {
        background: map-get($theme, 'background-focused');
        color: map-get($theme, 'color-focused');
    }

    &:not(.readonly):not(.tag--editing):active {
        background: map-get($theme, 'background-active');
        color: map-get($theme, 'color-active');
    }

    &:not(:focus):not(.tag--editing):not(:active):not(.readonly):hover {
        background: map-get($theme, 'background-hover');
        color: map-get($theme, 'color-hover');
    }

    &.readonly {
        cursor: default;
    }

    &.readonly:focus,
    &:focus {
        outline: 0;
    }

    &.tag--editing {
        background-color: map-get($theme, 'background-editing');
        cursor: text;
    }

    &:hover {
        padding-right: 18px;

        .tag-wrapper {
            delete-icon {
                opacity: 1;

                span {
                    svg {
                        padding-right: 6px;
                        display: block;
                        //  right: 6px;
                    }
                }
            }
        }
    }
}
@mixin tag-input-form-theme($theme) {
    display: inline;
    vertical-align: middle;
    border: map-get($theme, 'input-border');
    padding: map-get($theme, 'input-padding');
    height: map-get($theme, 'input-height');
    font-size: map-get($theme, 'input-font-size');
    font-family: map-get($theme, 'font-family');

    &:focus {
        outline: 0;
    }
}
@mixin icon-theme($theme) {
    width: auto;
    height: map-get($theme, 'height');
    // transition: map-get($theme, 'transition');
    opacity: 0;
    text-align: right;
    font-size: 100px;
    transform: map-get($theme, 'transform');
    -webkit-transition: all 150ms ease-in;
    -moz-transition: all 150ms ease-in;
    -o-transition: all 150ms ease-in;
    -ms-transition: all 150ms ease-in;
    transition: all 150ms ease-in;

    path {
        fill: map-get($theme, 'fill');
    }

    svg {
        vertical-align: middle;
        height: 13px;
        display: none;
        position: absolute;
        right: 0;
        top: 5px;
    }

    &:not(.readonly):not(.tag--editing):focus {
        color: map-get($theme, 'color-focused');
    }

    &:not(.readonly):not(.tag--editing):active {
        color: map-get($theme, 'color-active');
    }

    &:not(:focus):not(.tag--editing):not(:active):not(.readonly):hover {
        color: map-get($theme, 'color-hover');
    }
}
@mixin icon-theme-focused($theme) {
    path {
        fill: map-get($theme, 'fill-focus');
    }
}
$filled-primary: $brand-default;
$filled-primary-dark: darken($filled-primary, 10%);
// this is the container's theme
$filled-theme: ( container-border-bottom: 1px solid $filled-primary );
// this is the tag's theme
$filled-tag-theme: ( cursor: pointer, margin: 8px 3px 5px 0px, position: relative, padding: 5px 8px, border-radius: 12px, color: #FFFFFF, font-weight: 500, font-size: 0.75em, text-transform: uppercase, display: inline-block, line-height: 1.5em, padding-left: 0.8em );
// this is the delete icon's theme
$filled-icon-theme: ( fill: #ffffff, fill-focus: #eee, transform: none, );
// apply theme to the container
.ng2-tag-input.filled-theme {
    @include tag-input-theme($filled-theme);
}
// apply theme to the tags
.ng2-tag-input.filled-theme tag {
    @include tag-theme($filled-tag-theme);
}
// apply theme to the delete icon
.ng2-tag-input.filled-theme tag delete-icon {
    @include icon-theme($filled-icon-theme);
}

tag-input {
    display: inline-block;
    padding: 4px 6px;
    max-width: 100%;
    line-height: 22px;
}