/*!

 =========================================================
 * Material Dashboard PRO Angular - v2.6.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-angular2
 * Copyright 2018 Creative Tim (https://www.creative-tim.com)

 * Designed by www.invisionapp.com Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
 @import "~@angular/material/prebuilt-themes/indigo-pink.css";

 @import "core/variables";
 @import "core/mixins";
 @import "~bootstrap/scss/bootstrap";
 @import "core/reboot";

// Core Components
@import "core/layout";
@import "core/buttons";
@import "core/checkboxes";
@import "dashboard/cards";
@import "core/custom-forms";
@import "core/switches";
@import "core/example-pages";
@import "core/radios";
@import "core/carousel";
@import "dashboard/forms";
@import "core/input-group";
@import "core/list-group";
@import "core/nav";
@import "core/images";
@import "dashboard/navbar";
@import "core/badges";
@import "dashboard/alerts";
@import "core/pagination";
@import "core/pills";
@import "core/info-areas";
@import "core/type";
@import "core/tabs";
@import "core/footers";
@import "core/tooltip";
@import "core/popover";
@import "core/modal";
@import "core/dropdown";
@import "core/headers";
@import "core/drawer";
@import "core/progress";
@import "core/togglebutton";
@import "core/ripples";
@import "dashboard/rtl";
@import "dashboard/sidebar-and-main-panel";
@import "dashboard/timeline";
@import "dashboard/fixed-plugin";
@import "dashboard/tables";
@import "dashboard/misc";
@import "dashboard/pages";

 // Components for PRO
 @import "dashboard/social-buttons";
 @import "dashboard/fileupload";

 //plugin css
 @import "plugins/plugin-nouislider";
 @import "plugins/animate";
 @import "plugins/sweetalert2";
 // @import "plugins/datatables.net";
 @import "plugins/jquery.jvectormap";
 @import "plugins/plugin-datetime-picker";
 @import "plugins/fullcalendar";
 @import "plugins/wizard-card";
 @import "plugins/chartist";
 @import "~chartist/dist/scss/chartist";
 @import "plugins/select-bootstrap";
 @import "plugins/perfect-scrollbar";
 @import "plugins/plugin-tagsinput";



@import "dashboard/responsive";


.mat-tab-body-content {
    width: 100;
    height: 100%;
    overflow: hidden !important;
  }
  .menu-spacer {
    flex: 1 1 auto;
  }
  .panel {
    margin: 5px !important;
    padding: 5px;
    box-shadow: 1px 3px 1px 0px #d9d9d9;
    .panel-title {
      width: 100%;
      font-weight: bold;
      border-bottom: 1px solid lightgray;
    }
    .panel-sub {
      width: 100%;
      font-weight: lighter;
    }
  
    .panel-action {
      width: 100%;
    }
  }
  .c2 {
    width: 50px;
  }
  .col {
    padding: 4px;
  }
  .btn-plus {
    // border: 1px solid black;
    border-radius: 5px;
    padding: 1px;
    cursor: pointer;
  }
  
  .btn-plus:hover {
    opacity: 0.5;
  }
  .mat-form-field {
    width: 100%;
  }
  .form-group label {
    font-weight: bold;
  }
  
  
  table .btn {
    margin: 0px !important;
    padding: 0px 5px 0px 5px;
  }
  
  .btn-actions * {
    margin: 5px !important;
  }
  // .custom-modalbox {
  //     mat-dialog-container {
  //         padding: 0px;
  //     }
  
  //     .mat-dialog-title {
  //         background-color:red;
  //     }
  // }
  .search-pane{
    float: left;
    margin-right: 10px;
  }
  .custom-field{
    background-color: #FFFFFF;
    border: 1px solid #DDDDDD;
    border-radius: 4px;
    padding: 3px;
  }
  