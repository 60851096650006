@media print {
    .isPrinting > * { display: none; }
    .isPrinting app-print-layout { display: block;}
    .header{
        font-size: 1.2em;
    }
  }
  .print-page { page-break-after: always;font-family: Arial; font-weight: normal; color: black;}
  .print-page:last-child { page-break-after: avoid; }    
  .print-page,   .print-page *,   .print-page  .print-text{
      font-size: 22px !important;
  }

.print-page th, .print-page td {
    padding: 0.25rem;
    text-align: left;
    border: 1px solid #ccc;
  }


.btn-dialog{
  font-variant: normal;
  text-transform: capitalize;
  margin-right: 10px;
  border: 0px;
  border-radius: 8px;
  padding: 5px;
  width: 120px;
  font-family: 'Your Font', sans-serif;
  font-weight: bold;

}

.btn-confirm{
  background-color: green;
  color: #ccc;

}

.btn-deny{
  background-color: blue;
  color: #ccc;
}

.btn-cancel{
  background-color: red;
  color: #ccc;
}