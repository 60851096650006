@import "~datatables.net-dt/css/jquery.dataTables.min.css";
@import "~datatables.net-buttons-dt/css/buttons.dataTables.min.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~material-design-icons/iconfont/material-icons.css";
@import "./print.css";

.cdk-overlay-connected-position-bounding-box,
.cdk-global-overlay-wrapper,
.cdk-overlay-container {
    z-index: 99999 !important;
}
.alert {
    z-index: 999999 !important;
}